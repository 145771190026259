"use client";
import { useSettings } from "@/utils/userSettings";
import { useUser } from "@clerk/nextjs";
import Link from "next/link";
import React from "react";

export default function HowMoneylineWorks() {
  const settings = useSettings();
  const { isLoaded } = useUser();
  const [showBanner, setShowBanner] = React.useState(true);

  React.useEffect(() => {
    if (isLoaded) {
      const initialBannerStatus = checkBannerStatus();
      setShowBanner(initialBannerStatus);
    }
  }, [isLoaded]);

  
  React.useEffect(() => {
    if (!settings.loading && settings.settings && !settings.settings.createdFirstPick) {
      setShowBanner(checkBannerStatus());
    }
  }, [settings, isLoaded]);
  
  

  const checkBannerStatus = () => {
    const bannerClicked = localStorage.getItem('hmwBannerClicked');
    return bannerClicked !== 'true';
  };

  const handleClick = () => {
    localStorage.setItem('hmwBannerClicked', 'true');
    setShowBanner(false);
  };
  

  if (!showBanner ||  settings.loading || !isLoaded ) {
    return;
  }
  if (showBanner) {
    return (
      <>
        <Link
          href="/how-moneyline-works"
          className="lg:block hidden container relative h-auto mt-3"
          onClick={handleClick}
        >
          <img
            className="w-full"
            src="/images/how-moneyline-works-desktop.webp"
            alt="How Moneyline Works"
          />
        </Link>
        <Link
          href="/how-moneyline-works"
          className="md:block lg:hidden hidden container relative h-auto mt-4"
          onClick={handleClick}
        >
          <img
            className="w-full"
            src="/images/how-moneyline-works-tablet.webp"
            alt="How Moneyline Works"
          />
        </Link>
        <Link
          href="/how-moneyline-works"
          className="md:hidden block container relative h-auto mt-4"
          onClick={handleClick}
        >
          <img
            className="w-full object-contain"
            src="/images/how-moneyline-works-mobile.webp"
            alt="How Moneyline Works"
          />
        </Link>
      </>
    );
  }
}
