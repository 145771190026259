"use client";
import React from "react";
import Icon from "@/components/primitives/icon";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import { SlideComponent } from "@splidejs/splide";
import ReactTimeAgo from "react-time-ago";
import Link from "next/link";

interface NewsCarouselArticle {
  attributes: {
    title: string;
    league: string;
    slug: string;
    timestamp: string;
    game_changer: boolean;
    image: {
      data: {
        attributes: {
          url: string;
          formats: any;
        };
      };
    };
    publishedAt: string;
  };
}
interface NewsCarouselProps {
  articles: NewsCarouselArticle[];
}
export default function NewsCarousel(props: NewsCarouselProps) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const splideRef = React.useRef<Splide>(null);

  function changeSlide(idx: number) {
    setActiveIndex(idx);
  }

  const handlePaginationClick = (index: number) => {
    splideRef.current?.splide?.go(index);
  };

  return (
    <div className="relative -ml-3 -mr-3 desktop-grid:mx-0 overflow-hidden">
      <Splide
        ref={splideRef}
        hasTrack={false}
        onMove={(splide, index, prev, dest) => {
          changeSlide(index);
        }}
        options={{
          type: "loop",
          rewind: true,
          autoplay: true,
          interval: 8000,
          progress: true,
          arrows: false,
          resetProgress: false,
          height: "500px",
          pagination: false,
          waitForTransition: false,
          pauseOnHover: false,
          lazyLoad: "nearby",
        }}
      >
        <SplideTrack className="rounded-none desktop-grid:rounded-xl overflow-hidden">
          {props.articles.length > 0 &&
            props.articles?.map((article, i) => {
              const { title, league, publishedAt, image, slug, game_changer } =
                article.attributes;

              const thumbnailUrl =
                image?.data?.attributes.formats?.large.url ||
                image?.data?.attributes.url;
              return (
                <SplideSlide key={i}>
                  <div className="min-h-0 h-full">
                    <Link
                      href={`/news/${slug}`}
                      prefetch={false}
                      className={`relative block h-full inset-0 z-0 overflow-hidden after:content-[''] after:left-0 after:bottom-0 after:opacity-90 after:h-1/2 after:w-full after:absolute after:bg-news-slider-gradient w-full h-[25rem] bg-center bg-cover`}
                      style={{
                        backgroundImage: `url(${thumbnailUrl})`,
                      }}
                    >
                      {game_changer && (
                        <div className="absolute z-1 top-6 right-6">
                          <GameChanger />
                        </div>
                      )}
                      <div className="max-w-[480px] absolute z-20 bottom-6 left-6 flex flex-col gap-3">
                        <div className="uppercase text-display text-white">
                          {title}
                        </div>
                        <div className="flex gap-2 text-white text-title-sm-medium">
                          <span>{league}</span>
                          <span>•</span>
                          <span>
                            <ReactTimeAgo
                              date={new Date(publishedAt)}
                              locale="en-US"
                              timeStyle="round"
                            />
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SplideSlide>
              );
            })}
        </SplideTrack>

        <Pagination
          totalSlides={props.articles.length}
          activeSlide={activeIndex}
          onPaginationClick={handlePaginationClick}
        />
      </Splide>
    </div>
  );
}

function GameChanger() {
  return (
    <>
      {/* Make into Badge component */}
      <div className="relative flex gap-1 text-label-lg-bold bg-primary text-on-primary p-1 items-center uppercase rounded-sm">
        <Icon name="flash" size="sm" />
        Game Changer
        <Icon
          name="sparkle-group"
          className="absolute inset-0 fill-white w-[139px] h-[46px] -top-[10px] -left-[16px]"
        ></Icon>
      </div>
    </>
  );
}

interface PaginationProps {
  totalSlides: number;
  activeSlide: number;
  onPaginationClick: (index: number) => void;
}
function Pagination(props: PaginationProps) {
  return (
    <div className="custom-pagination absolute z-20 right-6 bottom-8 flex justify-center gap-2">
      {Array.from({ length: props.totalSlides }, (_, index) => (
        <button
          key={index}
          className={`relative h-1 rounded-[2px] transition-all overflow-hidden ${
            props.activeSlide === index ? "w-8" : "w-4"
          }`}
          onClick={() => props.onPaginationClick(index)}
          title={`Go to slide ${index + 1}`}
        >
          <div className="absolute inset-0 bg-surface-700"></div>
          <div
            className={`progress absolute h-full left-0 top-0 w-0 bg-[#AEE549] ${
              props.activeSlide === index
                ? "block animate-pagination"
                : "hidden"
            }`}
          ></div>
        </button>
      ))}
    </div>
  );
}
